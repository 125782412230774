/**
*
*	Name:			IRANYekan Font
*	Version:			3.3
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Sep 17, 2020
*	Updated on:		Sep 17, 2020
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت ایران یکان یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANYekan fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (HP3F98)
--------------------------------------------------------------------------------------
*	
**/

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/eot/iranyekanwebboldfanum.eot');
    src: url('./fonts/eot/iranyekanwebboldfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebboldfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebboldfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/eot/iranyekanwebthinfanum.eot');
    src: url('./fonts/eot/iranyekanwebthinfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebthinfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebthinfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/eot/iranyekanweblightfanum.eot');
    src: url('./fonts/eot/iranyekanweblightfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanweblightfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanweblightfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/eot/iranyekanwebregularfanum.eot');
    src: url('./fonts/eot/iranyekanwebregularfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebregularfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebregularfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/eot/iranyekanwebmediumfanum.eot');
    src: url('./fonts/eot/iranyekanwebmediumfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebmediumfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebmediumfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/eot/iranyekanwebextraboldfanum.eot');
    src: url('./fonts/eot/iranyekanwebextraboldfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebextraboldfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebextraboldfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 850;
    src: url('./fonts/eot/iranyekanwebblackfanum.eot');
    src: url('./fonts/eot/iranyekanwebblackfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebblackfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebblackfanum.ttf') format('truetype');
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/eot/iranyekanwebextrablackfanum.eot');
    src: url('./fonts/eot/iranyekanwebextrablackfanum.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./fonts/woff/iranyekanwebextrablackfanum.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./fonts/ttf/iranyekanwebextrablackfanum.ttf') format('truetype');
}

:root {
    --primary-1: #1811b7;
    --primary-2: #0d207c;
    --secondry-1: #d33535;
    --secondry-2: #a60185;
    --o-prime-1: #00b871;
    --o-prime-2: #63b87a;
    --body-1: #ebedf6;
    --body-2: #fff;
    --body-3: #000;
    --text-1: #b0c8e0;
    --text-2: #484849;
    --text-3: #fff;
    --text-4: #b7b7b7;
    --radius: 10px;
    --border-radius: 20px;
    --border-radius-2: 8px;
    --shadow: 0 8px 10px #00000024;
    /* --shadow-2: 0 8px 15px #00000034; */
    --shadow-3: 0 4px 15px #00000034;
    --shadow-4: 0 2px 10px -3px #848484;
    --shadow-5: 0 3px 2px rgb(0, 0, 0, 0.1);
    /* rgb(132,132,132, 0.1)  */
    --fs-heading-1: 24px;
    --fs-body-1: 18px;
    --fs-title-1: 16px;
    /* home sizes */
    --fs-home-main-center: 21px;
    --fs-home-search: 14px;
    --fs-home-shop-search: 18px;
    --fs-home-shop-headline: 28px;
    --fs-home-shop-body: 23px;
    --fs-home-fav-headline: 36px;
    --fs-home-fav-price: 32px;
    --fs-home-fav-icon: 43px;
    /* about us sizes */
    --fs-au-main-headline: 56px;
    --fs-au-headline: 28px;
    --fs-au-body: 21px;
    /* sliding cart v2 */
    --fs-title: 35px;
    --fs-sub-title: 19px;
    --stroke-size: 0.5px;
    --fs-price: 36px;
    --fs-price-toman: 15px;
}

body {
    margin: 0;
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-2);
    letter-spacing: -0.75px !important;
}

input {
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-seri;
    letter-spacing: -0.75px !important;
}

textarea {
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-seri;
    letter-spacing: -0.75px !important;
}

button {
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-seri;
    letter-spacing: -0.75px !important;
}

.MuiFormLabel-root {
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-seri !important;
    letter-spacing: -0.75px !important;
}

.MuiButton-root {
    font-size: 1rem !important;
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-seri !important;
    letter-spacing: -0.75px !important;
}

.small-text {
    font-size: 0.8rem !important;
}

.bsmall-text {
    font-size: 0.8rem !important;
    font-weight: 800 !important;
}

.normal-text {
    font-size: 1rem !important;
}

.bnormal-text {
    font-size: 1rem !important;
    font-weight: 800 !important;
}

.big-text {
    font-size: 1.5rem !important;
}

.bbig-text {
    font-size: 1.5rem !important;
    font-weight: 800 !important;
}

.xbig-text {
    font-size: 2rem !important;
    letter-spacing: -1px !important;
}

.bxbig-text {
    font-size: 2rem !important;
    font-weight: 800 !important;
    letter-spacing: -0.75px !important;
}

.txbig-text {
    font-size: 2rem !important;
    font-weight: 800 !important;
}

.thin-text {
    font-weight: 300 !important;
}

.n-regular-text {
    font-size: 1.25rem;
}

.n-medium-text {
    font-size: 1.37rem;
}

.n-exta-text {
    font-size: 1.43rem;
    font-weight: 800;
}

@media screen and (max-width: 1500px) {
     :root {
        /* home sizes */
        --fs-home-main-center: 15px;
        --fs-home-search: 12px;
        --fs-home-shop-search: 15px;
        --fs-home-shop-headline: 19px;
        --fs-home-shop-body: 15px;
        --fs-home-fav-headline: 26px;
        --fs-home-fav-price: 21px;
        --fs-home-fav-icon: 29px;
        /* about us sizes */
        --fs-au-main-headline: 40px;
        --fs-au-headline: 21px;
        --fs-au-body: 14px;
        /* sliding cart v2 */
        --fs-title: 24px;
        --fs-sub-title: 14px;
        --stroke-size: 0.5px;
        --fs-price: 28px;
        --fs-price-toman: 14px;
    }
}

@media screen and (max-width: 750px) {
     :root {
        /* home sizes */
        --fs-home-main-center: 15px;
        --fs-home-search: 12px;
        --fs-home-shop-search: 15px;
        --fs-home-shop-headline: 19px;
        --fs-home-shop-body: 15px;
        --fs-home-fav-headline: 26px;
        --fs-home-fav-price: 21px;
        --fs-home-fav-icon: 29px;
        /* about us sizes */
        --fs-au-main-headline: 40px;
        --fs-au-headline: 21px;
        --fs-au-body: 14px;
    }
}

@media screen and (max-width: 500px) {
     :root {
        /* home sizes */
        --fs-home-main-center: 19px;
        --fs-home-search: 12px;
        --fs-home-shop-search: 14px;
        --fs-home-shop-headline: 18px;
        --fs-home-shop-body: 15px;
        --fs-home-fav-headline: 26px;
        --fs-home-fav-price: 21px;
        --fs-home-fav-icon: 16px;
        /* about us sizes */
        --fs-au-main-headline: 40px;
        --fs-au-headline: 21px;
        --fs-au-body: 14px;
    }
}

@media screen and (max-width: 400px) {
     :root {
        /* home sizes */
        --fs-home-main-center: 16px;
        --fs-home-search: 11px;
        --fs-home-shop-search: 12px;
        --fs-home-shop-headline: 15px;
        --fs-home-shop-body: 13px;
        --fs-home-fav-headline: 26px;
        --fs-home-fav-price: 21px;
        --fs-home-fav-icon: 13px;
        /* about us sizes */
        --fs-au-main-headline: 40px;
        --fs-au-headline: 21px;
        --fs-au-body: 14px;
    }
}