@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300&display=swap');
html {
    font-size: 62.5%;
}

.App {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    @media screen and (max-width: 760px) {
        min-height: 150vh;
    }
}